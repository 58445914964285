.ProjectCards {
  height: 70%;
  min-width: 90%;
  margin: auto;
  display: flex;
  flex-flow: wrap;
  align-items: stretch;
  justify-content: center;
}

@media (min-width: 1200px) {

  .ProjectCards {
    max-width: 1300px;
    min-width: unset;
    gap: 1rem;
    /* width: 1000px; */
  }
}